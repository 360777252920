import React from "react"
import clsx from "clsx"
import Link from "gatsby-plugin-transition-link"
import getAppUrl from "@src/utils/getAppUrl"
import Circle from "@src/icons/v4/circle.png"
import Circle2 from "@src/icons/v4/circle-2.png"
import Square from "@src/icons/v4/square.png"
import Triangle from "@src/icons/v4/triangle.png"

import * as styles from "./Splash.module.scss"
import gsap from "gsap"

export default function Splash({ location }) {
  const appUrl = getAppUrl.hook(location)

  React.useEffect(() => {
    const tl = gsap.fromTo(
      `.${styles.section}`,
      { opacity: 0 },
      {
        duration: 2,
        opacity: 1,
      }
    )

    return () => {
      tl.kill()
    }
  }, [])
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>
        Build full-stack applications without code
      </h1>
      <p className={styles.subtitle}>
        Build and deploy production-grade applications without code.
        <br />
        Get started in minutes and master it in hours.
      </p>
      <div className={styles.ctas}>
        <a className={styles.cta} href={appUrl} target="_blank">
          Get started
        </a>
        <Link
          className={clsx(styles.cta, styles.secondary)}
          to={"get-a-demo"}
          entry={{ length: 0.3 }}
          exit={{ length: 0.3 }}
        >
          Request a demo
        </Link>
      </div>
      <img src={Circle} className={styles.circle} />
      <img src={Circle2} className={styles.circle2} />
      <img src={Square} className={styles.square} />
      <img src={Triangle} className={styles.triangle} />
    </section>
  )
}
